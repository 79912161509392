jQuery(document).ready(function($) {
	// Mobile menu
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-left"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		$(".mobile-navigation-menu").data( "mmenu" ).open();
	});

	// Fit Vids
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-section").fitVids({ ignore: "nofit" });
		$(".homeboxes").fitVids({ ignore: "nofit" });
	}

	// Search form
	$(".search-reveal").click(function() {
		$(".search-form").toggleClass("active");
	});

	// Webflow code
	! function(o, c) {
		var n = c.documentElement,
			t = " w-mod-";
		n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
	}(window, document);

	//  Webflow: Interactions: Init
	Webflow.require('ix').init([
		{slug: "nav-button",name: "nav button",value: {style: {},triggers: [{type: "hover",stepsA: [{title: "Spin and Fade",opacity: 0.25,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",rotateX: "0deg",rotateY: "0deg",rotateZ: "180deg"}],stepsB: [{opacity: 1,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",rotateX: "0deg",rotateY: "0deg",rotateZ: "-180deg"}]}]}},
		{slug: "dropdown-hover-button",name: "Dropdown Hover Button",value: {style: {},triggers: [{type: "hover",selector: ".nav-dropdown-list",siblings: true,stepsA: [{display: "block",opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 250ms ease 0ms, height 150ms ease 0ms"}],stepsB: [{wait: 150},{opacity: 0,height: "0px",transition: "opacity 250ms ease 0ms, height 150ms ease 0ms"},{display: "none"}]}]}},
		{slug: "dropdown-hover-list",name: "Dropdown Hover List",value: {style: {},triggers: [{type: "hover",stepsA: [{display: "block"}],stepsB: [{wait: 150},{opacity: 0,height: "0px",transition: "opacity 250ms ease 0ms, height 150ms ease 0ms"},{display: "none"}]}]}},
		{slug: "dropdown-hover-arrow",name: "Dropdown Hover Arrow",value: {style: {},triggers: [{type: "click",selector: ".nav-dropdown-list",siblings: true,stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "two-part-button-button-arrow-turn",name: "Two Part Button Button Arrow Turn",value: {style: {},triggers: [{type: "click",selector: ".two-part-button-arrow",descend: true,preserve3d: true,stepsA: [{rotateX: "0deg",rotateY: "0deg",rotateZ: "45deg"}],stepsB: [{rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"}]}]}},
		{slug: "mega-menu-trigger",name: "Mega Menu Trigger",value: {style: {},triggers: [{type: "hover",selector: ".mega-menu-dropdown-list",siblings: true,stepsA: [{display: "block",opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 250ms ease 0ms, height 150ms ease 0ms"}],stepsB: [{wait: 150},{opacity: 0,height: "0px",transition: "opacity 250ms ease 0ms, height 150ms ease 0ms"},{display: "none"}]}]}},
		{slug: "mega-menu-list",name: "Mega Menu List",value: {style: {},triggers: [{type: "hover",stepsA: [{display: "block"}],stepsB: [{wait: 150},{opacity: 0,height: "0px",transition: "opacity 250ms ease 0ms, height 150ms ease 0ms"},{display: "none"}]}]}},
		{slug: "reveal-mobile-search",name: "Reveal Mobile Search",value: {style: {},triggers: [{type: "click",selector: ".mobile-search-wrap-container",stepsA: [{display: "block",height: "0px"},{height: "68px",transition: "height 500ms ease 0ms"}],stepsB: [{height: "0px",transition: "height 500ms ease 0ms"},{display: "none",height: "68px"}]}]}},
		{slug: "estimate-shipping-reveal",name: "Estimate Shipping Reveal",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-estimate-shipping-form-wrap",stepsA: [{display: "block"}],stepsB: []},{type: "click",stepsA: [{display: "none"}],stepsB: []}]}},
		{slug: "show-estimated-shipping",name: "Show Estimated Shipping",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-estimate-shipping-form-wrap",stepsA: [{display: "none"}],stepsB: []},{type: "click",selector: ".sticky-cart-estimated-shipping-figure",stepsA: [{display: "block"}],stepsB: []}]}},
		{slug: "edit-sticky-cart-shipping",name: "Edit Sticky Cart Shipping",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-estimated-shipping-figure",stepsA: [{display: "none"}],stepsB: []},{type: "click",selector: ".sticky-cart-estimate-shipping-form-wrap",stepsA: [{display: "block"}],stepsB: []}]}},
		{slug: "sticky-cart-info-reveal-button",name: "Sticky Cart Info Reveal Button",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-close-image",stepsA: [{display: "block"}],stepsB: [{display: "none"}]},{type: "click",selector: ".sticky-cart-open-image",stepsA: [{display: "none"}],stepsB: [{display: "block"}]},{type: "click",selector: ".sticky-cart-info-reveal",stepsA: [{display: "block",height: "0px"},{height: "auto",transition: "height 500ms ease 0ms"}],stepsB: [{height: "0px",transition: "height 500ms ease 0ms"},{display: "none"}]},{type: "click",selector: ".sticky-cart-header-checkout",stepsA: [{opacity: 0,transition: "opacity 350ms ease 0ms"},{display: "none"}],stepsB: [{wait: 150},{display: "block",opacity: 0},{opacity: 1,transition: "opacity 350ms ease 0ms"}]},{type: "click",selector: ".sticky-cart-open-text",stepsA: [{display: "none"}],stepsB: [{display: "block"}]},{type: "click",selector: ".sticky-cart-close-text",stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "new-dropdown-animation",name: "New Dropdown Animation",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 125ms ease 0, height 125ms ease 0"}],stepsB: [{wait: "500ms"},{opacity: 0,height: "0px",transition: "opacity 350ms ease 0, height 350ms ease 0"}]}]}},
		{slug: "new-dropdown-animation-mega",name: "New Dropdown Animation - Mega",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{wait: "250ms"},{opacity: 1,height: "auto",transition: "opacity 250ms ease 0, height 250ms ease 0"}],stepsB: [{wait: "500ms"},{opacity: 0,height: "0px",transition: "opacity 250ms ease 0, height 250ms ease 0"}]}]}},
		{slug: "close-shipping-estimate",name: "Close Shipping Estimate",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-shipping-estimate-reveal",stepsA: [{opacity: 0,transition: "opacity 150ms ease 0ms"},{display: "none"}],stepsB: []}]}},
		{slug: "close-popup-details",name: "Close Popup Details ",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-popup-details-reveal",stepsA: [{opacity: 0,transition: "opacity 150ms ease 0ms"},{display: "none"}],stepsB: []}]}},
		{slug: "reveal-sticky-cart-popup-details",name: "Reveal sticky cart popup details",value: {style: {},triggers: [{type: "hover",selector: ".sticky-cart-popup-details-reveal",stepsA: [{display: "block",opacity: 0},{opacity: 1,transition: "opacity 150ms ease 0ms"}],stepsB: [{wait: 200},{opacity: 0,transition: "opacity 150ms ease 0ms"},{display: "none"}]},{type: "hover",selector: ".sticky-cart-shipping-estimate-reveal",stepsA: [{display: "none"}],stepsB: []}]}},
		{slug: "sticky-cart-initial-appearance-hide",name: "Sticky Cart Initial Appearance Hide",value: {style: {display: "none"},triggers: []}},
		{slug: "estimate-shipping-reveal-new",name: "Estimate Shipping Reveal New",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-shipping-estimate-reveal",stepsA: [{display: "block",opacity: 0},{opacity: 1,transition: "opacity 150ms ease 0ms"}],stepsB: []},{type: "click",selector: ".sticky-cart-popup-details-reveal",stepsA: [{display: "none"}],stepsB: []}]}},
		{slug: "confirm-shipping-button",name: "Confirm Shipping Button",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-estimate-shipping-text",stepsA: [{display: "none"}],stepsB: []},{type: "click",selector: ".sticky-cart-estimated-shipping-figure",stepsA: [{display: "block"}],stepsB: []},{type: "click",selector: ".sticky-cart-shipping-estimate-reveal",stepsA: [{opacity: 0,transition: "opacity 150ms ease 0ms"},{display: "none"}],stepsB: []}]}},
		{slug: "reveal-sticky-cart-popup-details-2",name: "Reveal sticky cart popup details 2",value: {style: {},triggers: [{type: "click",selector: ".sticky-cart-popup-details-reveal",stepsA: [{display: "block",opacity: 0},{opacity: 1,transition: "opacity 150ms ease 0ms"}],stepsB: []},{type: "click",selector: ".sticky-cart-shipping-estimate-reveal",stepsA: [{display: "none"}],stepsB: []}]}}
	]);

	// Accessible menu
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link").click(function(event) {
			if ( !$(this).parents(".hasChildren").hasClass("open") ) {
				$(".new-dropdown-toggle").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".new-dropdown-toggle").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".desktop-navigation-menu").accessibleMenu();
});
